export enum TeamNavIndex {
    Dashboard,
    TeamProfile,
    People,
    TeamBenefits,
    Pathways,
    ReimbursementReports,
    Payroll,
    Files,
    Notes,
    Hba,
    Roles,
    Integrations,
}
