import { IAction } from 'actions/commonAction';
import { SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION } from 'actions/marketplacePlan/searchOffExchangeRecommendedPlans';
import { SEARCH_ON_EXCHANGE_RECOMMENDED_PLANS_ACTION } from 'actions/marketplacePlan/searchOnExchangeRecommendedPlans';
import { IMarketplacePlanDto } from 'api/generated/models';

const initialState: { offExchange: IMarketplacePlanDto[]; onExchange: IMarketplacePlanDto[] } = {
    offExchange: [],
    onExchange: [],
};

export const recommendedPlans = (
    state = initialState,
    action: IAction<IMarketplacePlanDto[]> & { isRefresh?: boolean }
): { offExchange: IMarketplacePlanDto[]; onExchange: IMarketplacePlanDto[] } => {
    switch (action.type) {
        case SEARCH_OFF_EXCHANGE_RECOMMENDED_PLANS_ACTION.success: {
            return { ...state, offExchange: action.data };
        }
        case SEARCH_ON_EXCHANGE_RECOMMENDED_PLANS_ACTION.success: {
            return { ...state, onExchange: action.data };
        }
        default:
            return state;
    }
};
