import { CLEAR_SHOPPING_PATHWAY_MARKETPLACE_PLAN } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import { IMarketplacePlanDto } from 'api/generated/models';

const initialState: IMarketplacePlanDto[] = [];

export const shoppingPathwayMarketplacePlan = (
    state = initialState,
    action: IAction<IMarketplacePlanDto[]>
): IMarketplacePlanDto[] => {
    switch (action.type) {
        case APPLY_SHOPPING_COVERAGE_CHANGES:
        case CLEAR_SHOPPING_PATHWAY_MARKETPLACE_PLAN:
            return [];
        case GET_PATHWAY_MARKETPLACE_PLAN_FOR_PATHWAY_USER_ACTION.success:
            return [...state, ...action.data];
        default:
            return state;
    }
};
