import { ReimbursementDisplayLevels, TeamBenefitTypes } from 'api/generated/enums';
import { IReimbursementOverlayDto } from 'api/generated/models';
import { hasValue } from 'utilities/index';

export const shouldShowFamilyMoopWithRhReimbursement = (
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined
) =>
    hasValue(reimbursementOverlayDto?.familyMoopWithRhReimbursement) &&
    [ReimbursementDisplayLevels.IndividualAndFamily, ReimbursementDisplayLevels.Family].contains(
        reimbursementOverlayDto?.displayLevelId
    );

export const shouldShowRhReimbursement = (
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined
) =>
    [
        ReimbursementDisplayLevels.Individual,
        ReimbursementDisplayLevels.IndividualAndFamily,
        ReimbursementDisplayLevels.Family,
    ].contains(reimbursementOverlayDto?.displayLevelId);

export const shouldDropDecimal = (reimbursementOverlayDto: IReimbursementOverlayDto | undefined) =>
    Number.isInteger(reimbursementOverlayDto?.individualMedicalResponsibility) &&
    Number.isInteger(reimbursementOverlayDto?.individualMedicalReimbursementAmount) &&
    Number.isInteger(reimbursementOverlayDto?.familyMedicalResponsibility) &&
    Number.isInteger(reimbursementOverlayDto?.familyMedicalReimbursementAmount);

export const getReimbursementOverlayLabel = (
    reimbursementOverlayDto: IReimbursementOverlayDto | undefined
) =>
    reimbursementOverlayDto?.teamBenefitType === TeamBenefitTypes.ReimbursementProgram
        ? 'RH '
        : 'HRA ';
