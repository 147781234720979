import App from 'components/App';
import CacheBuster from 'components/CacheBuster';
import NewVersion from 'components/newVersion/NewVersion';
import { APP_ROOT_ID } from 'constants/dom';
import { keysToBeSanitized } from 'constants/logrocket';
import DesktopContext from 'contexts/DesktopContext';
import device from 'current-device';
import { createBrowserHistory, History } from 'history';
import LogRocket from 'logrocket';
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from 'redux';
import 'scss/app.scss';
import 'scss/icons.scss';
import { TokenService } from 'security/TokenService';
import { configureStore } from 'store';
import RhThemeProvider from './RhThemeProvider';
import './web.config';

if (process.env['DEV_MODE'] === 'true') {
    console.log(`API_URL: ${process.env['API_URL']}`);
    console.log(`DEV_MODE: ${process.env['DEV_MODE']}`);
    console.log(`FINCH_CLIENT_ID: ${process.env['FINCH_CLIENT_ID']}`);
    console.log(`PACTSAFE_ACCESSKEY: ${process.env['PACTSAFE_ACCESSKEY']}`);
    console.log(`VERSION: ${process.env['VERSION']}`);
}

const isDesktop = device.desktop();

const history = createBrowserHistory();

const store = configureStore(history);

TokenService.loadTokenFromCache();

type IRequest = {
    body: string;
    credentials?: string;
    headers: Record<string, string | undefined>;
    method: string;
    mode?: string;
    referrer?: string;
    reqId: string;
    url: string;
};

type IResponse = {
    body: string;
    headers: Record<string, string | undefined>;
    method: string;
    reqId: string;
    status?: number;
};

if (process.env['LOGROCKET_PROJECT_ID']) {
    const {
        requestSanitizer,
        responseSanitizer,
    }: {
        requestSanitizer: (request: IRequest) => IRequest | null;
        responseSanitizer: (response: IResponse) => IResponse | null;
    } = LogRocketFuzzySanitizer.setup(keysToBeSanitized);
    LogRocket.init(process.env['LOGROCKET_PROJECT_ID'], {
        network: {
            requestSanitizer,
            responseSanitizer,
        },
        release: process.env['VERSION'],
    });
    setupLogRocketReact(LogRocket);
}

const render = (Component: React.FunctionComponent<{ history: History }>) => {
    ReactDOM.render(
        <Provider store={store}>
            <CacheBuster history={history}>
                <AppContainer>
                    <DesktopContext.Provider value={isDesktop}>
                        <RhThemeProvider>
                            <div>
                                <Component history={history} />
                                <ToastContainer style={{ whiteSpace: 'pre-line' }} />
                                <NewVersion />
                            </div>
                        </RhThemeProvider>
                    </DesktopContext.Provider>
                </AppContainer>
            </CacheBuster>
        </Provider>,
        document.getElementById(APP_ROOT_ID)
    );
};

if (module.hot) {
    module.hot.accept('components/App', () => {
        render(App);
    });
}

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        Cypress: unknown;
        store: Store;
    }
}
if (window.Cypress) {
    window.store = store;
}

render(App);
