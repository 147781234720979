import { IBasicMarketplacePlanDto, IMarketplacePlanDto, Issuer } from 'api/generated/models';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

export const getPlansByCarrier = (
    marketplacePlans: IBasicMarketplacePlanDto[] | undefined,
    carrier: string | undefined
) =>
    orderBy(
        filter(marketplacePlans, (plan) => plan.issuer?.name === carrier),
        'name'
    );

export const getUniqueMarketplaceCarriers = (
    marketplacePlans: IBasicMarketplacePlanDto[] | IMarketplacePlanDto[] | undefined
) =>
    orderBy(
        uniqBy(marketplacePlans, 'issuer.name')
            .map((plan) => {
                if (plan?.issuer?.name !== undefined) {
                    return plan.issuer;
                } else {
                    return undefined;
                }
            })
            .filter((x) => x !== undefined) as Issuer[],
        'name'
    );

export const getCarrierNameFromId = (carrierId: string, carriers: Issuer[]) =>
    carriers.find((c) => c.id === carrierId)?.name;

export const getPlan = (carrierId: string, planId: string, plans: IMarketplacePlanDto[]) =>
    plans.filter((p) => p.issuer?.id === carrierId && p.id === planId)[0];
